'use client'

import * as Sentry from '@sentry/nextjs'
import { ERROR } from '@/components/Error/consts'
import { SubPageHeader } from '@/v1/Header/SubPageHeader'

const height = 'calc(100vh - 48px)'

export default function Error({ error }: { error: Error }) {
  Sentry.captureException(error)

  const handleBack = () => {
    // TODO: useRouter를 사용하면 global 에러가 발생한다. (NextRouter was not mounted)
    // pages 디렉토리 제거 후 다시 시도해볼 것
    if (typeof window !== 'undefined') {
      window.history.back()
    }
  }

  return (
    <>
      <SubPageHeader />
      <div className="w-full" style={{ height }}>
        <div className="w-full h-full flex items-center justify-center text-center">
          <div className="px-md">
            <div className="mb-2.5">
              <strong className="whitespace-pre-wrap text-title">
                {ERROR['error'].title}
              </strong>
            </div>
            <div className="mb-5">
              <div className="whitespace-pre-wrap px-4 text-center">
                {ERROR['error'].content}
              </div>
            </div>
            <button
              type="button"
              className="w-full p-2.5 rounded-sm bg-gray-20"
              onClick={handleBack}
            >
              <span className="text-blue-100">이전 페이지로 돌아가기</span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
