export const ERROR = {
  '404': {
    title: '요청하신 페이지를 찾을 수 없습니다.',
    content: `입력하신 주소가 정확한지 \n다시 한번 확인해 주세요.`,
  },
  error: {
    title: '일시적인 오류가 발생하였습니다.',
    content: `이용에 불편을 드려 죄송합니다.\n새로고침하여 다시 시도해주시기 바랍니다.`,
  },
}
